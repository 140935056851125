import Reducer from './reducer';
import { AsdsReduxAction, SelectComponentProps } from '../../../../types';
import { createReducer } from '../utils/redux-util';
import ActionConstants from '../action-constants';
import { CategoryListingAPIRes, SelectCategoryValue } from '../../PurchaseSoftware/';
import CheckoutConstants from '../../constants/checkout-constants';

class CategoryListingReducer extends Reducer {

    private static getCategoryList(categoryListingResponse: CategoryListingAPIRes): SelectComponentProps<SelectCategoryValue>[] {
        const categories = Array.isArray(categoryListingResponse?.categories) ? categoryListingResponse.categories : [];
        const options = categories.map(category => ({
            value: {
                categoryId: category.categoryId,
                system: category.system,
            },
            text: category.categoryName,
            key: category.categoryId,
        }));
        options.unshift({
            value: {
                categoryId:CheckoutConstants.DEFAULT_CATEGORY,
                system: '',
            },
            text: CheckoutConstants.DEFAULT_CATEGORY_TEXT,
            key:'default',
        });
        return options;
    }

    static setCategoryListing(_state, action: AsdsReduxAction) {
        const categoryListingResponse: CategoryListingAPIRes = action.payload.response;
        return {
            payload: {
                selectedCategory: action.payload.selectedCategory,
                categoryListing: {
                    categoryList: CategoryListingReducer.getCategoryList(categoryListingResponse),
                    isFetching: false,
                    isSuccess: true,
                }
            }
        };
    }

    static getCategoryListing(_state, _action: AsdsReduxAction) {
        return {
            payload: {
                categoryListing: {
                    isFetching: true,
                    isSuccess: false,
                }
            }
        }
    }

    static setSelectedCategory(state, action: AsdsReduxAction) {
        return {
            payload: {
                ...state.payload,
                selectedCategory: action.payload.selectedCategory,
            }
        };
    }
}

const categoryListingHandlers = {
    [ActionConstants.GET_CATEGORY_LISTING]: CategoryListingReducer.getCategoryListing,
    [ActionConstants.SET_CATEGORY_LISTING]: CategoryListingReducer.setCategoryListing,
    [ActionConstants.SET_SELECTED_CATEGORY]: CategoryListingReducer.setSelectedCategory,
}

const initialState = {
    payload: {
        categoryListing: {
            isFetching: true,
            isSuccess: false,
        },
        selectedCategory: '',
    },
};

export const categories = createReducer(initialState, categoryListingHandlers);